import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33')
];

export const server_loads = [0,2,4,5];

export const dictionary = {
		"/(app)": [~6,[2],[3]],
		"/(app)/about": [7,[2],[3]],
		"/admin": [~19,[4]],
		"/admin/ask-engeltax": [~20,[4]],
		"/admin/ask-engeltax/[id]": [~21,[4]],
		"/admin/checklist": [~22,[4]],
		"/admin/cookies": [~23,[4]],
		"/admin/cookies/[id]": [~24,[4]],
		"/admin/free-quote": [~25,[4]],
		"/admin/free-quote/[id]": [~26,[4]],
		"/admin/users": [~27,[4,5]],
		"/admin/users/new": [~33,[4,5]],
		"/admin/users/[id]/access": [~28,[4,5]],
		"/admin/users/[id]/delete": [~29,[4,5]],
		"/admin/users/[id]/edit": [~30,[4,5]],
		"/admin/users/[id]/files": [~31,[4,5]],
		"/admin/users/[id]/files/delete": [~32,[4,5]],
		"/(app)/ask-engeltax": [~8,[2],[3]],
		"/(app)/cookies": [~9,[2],[3]],
		"/(app)/cookies/policy": [10,[2],[3]],
		"/(app)/file-with-engeltax": [~11,[2],[3]],
		"/(app)/files": [~12,[2],[3]],
		"/(app)/files/[id]/delete": [~13,[2],[3]],
		"/(app)/free-quote": [~14,[2],[3]],
		"/(app)/login": [~15,[2],[3]],
		"/(app)/login/confirm": [16,[2],[3]],
		"/(app)/login/token/[token]": [~17,[2],[3]],
		"/(app)/logout": [~18,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';